<template>
    <NavbarCompSecondary/>
    <div class="bg-yellow-400" id="Top">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <CompanySVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Our Team</h5>
                            <h5 class="mb-3 text-xl font-bold tracking-tight text-white text-justify">
                                We are more than just a travel agency; we are your partners in creating unforgettable journeys 
                                and lasting memories. At AirProve, we embody a commitment to excellence, affordability, and 
                                customer satisfaction.
                            </h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <p class="text-4xl text-center mb-5 font-bold" style="color: #1C3F60">Who Are We</p> <!--39, 8-->
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-80 w-96" src="../assets/logo_with_bg/2.png" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white text-center">
                            <div class="font-bold text-xl mb-2">Joseph Louie B. Solcoretes</div>
                            <p class="text-gray-700 text-base">
                                Owner/CEO of AirProve
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-80 w-96" src="../assets/logo_with_bg/1.png" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white text-center">
                            <div class="font-bold text-xl mb-2">Christian James V. Morisca</div>
                            <p class="text-gray-700 text-base">
                                Owner/COO of AirProve
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-80 w-96" src="../assets/logo_with_bg/3.png" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white text-center">
                            <div class="font-bold text-xl mb-2">John Justin M. Berdin</div>
                            <p class="text-gray-700 text-base">
                                Web Developer of AirProve
                            </p>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>
    </div>
    <PageFooter/>
</template>

<script>
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import CompanySVG from '@/components/CompanySVG.vue'
import PageFooter from '@/components/PageFooter.vue'

export default{
    components: { NavbarCompSecondary, CompanySVG, PageFooter }
}

</script>