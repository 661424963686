<template>
    <div class="bg-slate-100 pattern-topography-blue-500/50 pattern-topography-scale-[0.5] overflow-hidden">
        <NavbarCompSecondary/>
        <div class="flex items-center justify-center my-10">
            <div v-if="lengthAllFlights === 0">
                <div class="my-5">
                    <div class="">
                        <a class="flex flex-row items-center h-full">
                            <div class="flex flex-col justify-between p-4 leading-normal w-full">
                                <h5 class="mb-3 text-2xl font-bold tracking-tight text-blue-500 text-center">No flights available yet</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="page_loaded === false">
                    <div role="status" class="flex justify-center items-center my-5">
                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <div class="grid grid-cols-1 sm:grid-cols-1 sm:gap-2 gap-2">
                        <!-- 1 -->
                        <div v-for="allFlights in allFlights_arr" :key="allFlights">
                            <div v-if="postId === allFlights.id">
                                <div class="max-w-sm w-auto lg:max-w-full lg:flex mx-5">
                                    <img class="h-48 lg:h-auto lg:w-48 w-full flex-none object-cover rounded-t-lg lg:rounded-t-none lg:rounded-l-lg text-center overflow-hidden" :src="allFlights.photo_link">
                                    <div class="border-r border-b border-l lg:border-l-0 lg:border-t bg-white rounded-b-lg lg:rounded-b-none lg:rounded-r-lg p-4 flex flex-col justify-between leading-normal shadow-xl">
                                        <div class="mb-8">
                                            <div class="text-gray-900 font-bold text-4xl mb-2">{{ allFlights.destination }}</div>
                                            <p class="text-red-500 text-2xl font-bold"> ₱{{ allFlights.price }}</p>
                                            <p class="text-gray-700 text-base text-justify">{{ allFlights.description }}</p>
                                        </div>
                                        <div class="flex justify-end">
                                            <div class="px-6 pt-4 pb-2 flex items-center justify-center bg-white">
                                                <button @click="bookInternational(allFlights.id)" type="button" class="text-blue-700 w-80 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Book now!</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PageFooter/>

    <div v-if="modal === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5">
                    <h3 class="text-lg font-semibold text-gray-900 ">
                        Book Your Trip (Destination: {{ trip_name_holder }})
                    </h3>
                    <button @click="toggleModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <!-- Modal body -->
                <form class="p-4 md:p-5" @submit.prevent="bookTrip">
                    <div class="grid gap-4 mb-4 grid-cols-2">                  
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Trip Type</label>
                            <div class="flex">
                                <div class="flex items-center me-4">
                                    <input type="radio" value="round-trip" v-model="trip_type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Round Trip</label>
                                </div>
                                <div class="flex items-center me-4">
                                    <input type="radio" value="one-way" v-model="trip_type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">One Way Trip</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">No. of Passengers</label>
                            <input type="number" v-model="no_of_passengers" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. 4" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Departure</label>
                            <input type="date" v-model="departure" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. John T. Smith, James V. Doe" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Return</label>
                            <input type="date" v-model="return_from_trip" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. johnsmith@example.com" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Name/s</label>
                            <input type="text" v-model="name_of_people" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. John T. Smith, James V. Doe" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">E-mail</label>
                            <input type="email" v-model="email_add" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. johnsmith@example.com" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                            <input type="tel"  v-model="phone_numbers" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="09123456789" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Address</label>
                            <input type="text" v-model="address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Blk 1 lot 1 Sesame St. Brgy Pacol" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 ">City</label>
                            <input type="text"  v-model="city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Naga City" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">State/Province</label>
                            <input type="text" v-model="state_or_province" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Camarines Sur" required="">
                        </div>
                    </div>
                    <button type="submit" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                        <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                        Book
                    </button>
                </form>
            </div>
        </PopUpModal>
    </div>
</template>

<script>
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import PopUpModal from '@/components/PopUpModal.vue'
import PageFooter from '@/components/PageFooter.vue'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import Parse from 'parse';
Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('A9iU0asj7lBx8THtL3OuHVdKAebIUED9GJKzrpWn', 'AQmS27wK0tZHTPFga30Afa0eMTqnBmzf7zk9yX4F');

export default{
    data(){
        return{
            modal: false,
            id_holder: '',
            no_of_passengers: '',
            departure: '',
            return_from_trip: '',
            name_of_people: '',
            email_add: '',
            phone_numbers: '',
            address: '',
            city: '',
            state_or_province: '',
            trip_type: '',

            trip_name_holder: '',
            domestic_flights: [],
            international_flights: [],
            holiday_flights: [],

            length_of_dom: '',
            length_of_inter: '',
            length_of_holi: '',

            page_loaded: false,

            postDestination: this.$route.params.destination,
            postId: this.$route.params.id,

            allFlights_arr: [],
            lengthAllFlights: '',
        }
    },

    components: { NavbarCompSecondary, PageFooter, PopUpModal },

    methods: {
        outwardBoundPage(){
            this.$router.push({name: 'outward'});
        },
        cultureCurious(){
            this.$router.push({name: 'culture'});
        },
        relaxPage(){
            this.$router.push({name: 'relax'});
        },
        ourCompany(){
            this.$router.push({name: 'team'});
        },

        bookTrip(){
            const Booked_trip = Parse.Object.extend("bookings");
            const booked_trip = new Booked_trip();
            booked_trip.set("trip_type", this.trip_type);
            booked_trip.set("no_of_passengers", this.no_of_passengers);
            booked_trip.set("departure", this.departure);
            booked_trip.set("return_", this.return_from_trip);
            booked_trip.set("name", this.name_of_people);
            booked_trip.set("email", this.email_add);
            booked_trip.set("phone_number", this.phone_numbers);
            booked_trip.set("address", this.address);
            booked_trip.set("city", this.city);
            booked_trip.set("state_or_province", this.state_or_province);
            booked_trip.set("destination", this.trip_name_holder);
            booked_trip.set("status", "pending");

            booked_trip.save().then((booked_trip) => {
                console.log(booked_trip.id);
                this.popUp();
            }, (error) => {
                alert('Error:' + error.message);
            });

            this.modal = false;
            this.id_holder = "";
            this.no_of_passengers = "";
            this.departure = "";
            this.return_from_trip = "";
            this.name_of_people = "";
            this.email_add = "";
            this.phone_numbers = "";
            this.address = "";
            this.city = "";
            this.state_or_province = "";
            this.trip_type = "";
        },

        popUp(){
            Swal.fire({
                icon: 'success', title: "Booked!", text: "Wait for our admin to contact you thru e-mail and SMS",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
        },

        bookDomestic(id){
            this.id_holder = id;
            console.log(this.id_holder);
            this.modal = !this.modal;
            for(let j = 0; j < this.domestic_flights.length; j++){
                if(this.id_holder === this.domestic_flights[j].id){
                    this.trip_name_holder = this.domestic_flights[j].destination;
                    console.log("Domestic")
                }
            }
        },

        bookInternational(id){
            this.id_holder = id;
            console.log(this.id_holder);
            this.modal = !this.modal;
            for(let j = 0; j < this.allFlights_arr.length; j++){
                if(this.id_holder === this.allFlights_arr[j].id){
                    this.trip_name_holder = this.allFlights_arr[j].destination;
                    console.log("international")
                }
            }
        },

        bookHoli(id){
            this.id_holder = id;
            console.log(this.id_holder);
            this.modal = !this.modal;
            for(let j = 0; j < this.holiday_flights.length; j++){
                if(this.id_holder === this.holiday_flights[j].id){
                    this.trip_name_holder = this.holiday_flights[j].destination;
                    console.log("holiday package");
                }
            }
        },

        toggleModal(){
            this.modal = false;
            this.id_holder = '';
        },
    },

    mounted: async function(){
        const International = Parse.Object.extend("international");
        const international = new Parse.Query(International);
        const query_inter = await international.find();

        for(let i = 0; i < query_inter.length; i++){
            this.international_flights.push({
                id: query_inter[i].id,
                destination: query_inter[i].get("destination"),
                description: query_inter[i].get("description"),
                photo_link: query_inter[i].get("photo_link"),
                price: query_inter[i].get("price"),
                promo_code: query_inter[i].get("promo_code"),
            })
            this.page_loaded = true;
        }

        this.length_of_inter = this.international_flights.length;

        const Domestic = Parse.Object.extend("domestic");
        const domestic = new Parse.Query(Domestic);
        const query_dom = await domestic.find();

        for(let j = 0; j < query_dom.length; j++){
            this.domestic_flights.push({
                id: query_dom[j].id,
                destination: query_dom[j].get("destination"),
                description: query_dom[j].get("description"),
                photo_link: query_dom[j].get("photo_link"),
                price: query_dom[j].get("price"),
                promo_code: query_dom[j].get("promo_code"),
            })
            this.page_loaded = true;
        }

        this.length_of_dom = this.domestic_flights.length;

        const Holiday = Parse.Object.extend("holiday");
        const holiday = new Parse.Query(Holiday);
        const query_holi = await holiday.find();

        for(let j = 0; j < query_holi.length; j++){
            this.holiday_flights.push({
                id: query_holi[j].id,
                destination: query_holi[j].get("destination"),
                description: query_holi[j].get("description"),
                photo_link: query_holi[j].get("photo_link"),
                price: query_holi[j].get("price"),
                promo_code: query_holi[j].get("promo_code"),
            })
            this.page_loaded = true;
        }

        this.length_of_holi = this.holiday_flights.length;

        this.allFlights_arr = this.international_flights.concat(this.domestic_flights, this.holiday_flights);
        this.lengthAllFlights = this.allFlights_arr.length;
        this.page_loaded = true;
    }
}

</script>