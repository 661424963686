// path-to-authUtils.js
import Parse from 'parse';

Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('A9iU0asj7lBx8THtL3OuHVdKAebIUED9GJKzrpWn', 'AQmS27wK0tZHTPFga30Afa0eMTqnBmzf7zk9yX4F');

export const checkAdminStatus = async () => {
  try {
    const currentUser = Parse.User.current();

    if (currentUser) {
      const query = new Parse.Query(Parse.User);
      query.equalTo('objectId', currentUser.id);
      const user = await query.first();

      // Assuming you have an 'isAdmin' field in your user model
      return user.get('isAdmin') === true;
    }

    return false; // If no user is logged in
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false; // Handle errors by returning false
  }
};
