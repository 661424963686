<template>
    <NavbarComp/>
    <div class="bg-slate-100 pattern-topography-blue-500/50 pattern-topography-scale-[0.5]">
        <div class="flex items-center justify-center">
            <div class="">
                <a href="#" class="flex flex-row items-center h-full">
                    <div class="flex flex-col justify-between p-4 leading-normal w-full py-20">
                        <h5 class="mb-3 text-5xl font-bold tracking-tight text-blue-700 text-center">Terms & Conditions</h5>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="text-center text-justify sm:mx-40 mx-20 my-10">
        <ol class="list-disc">
            <li>
                <p class="text-xl font-semibold">Introduction</p>
                <br>
                Before making a reservation with <b>AIRPROVE TRAVEL AGENCY</b>, 
                we encourage you to read these Terms and Conditions carefully, 
                as they constitute a contract agreement between <b>AIRPROVE TRAVEL AGENCY</b> 
                and the Client from the time the booking is made. The person who makes 
                the booking accepts these conditions on behalf of all the people in the 
                group and is responsible for all payments due. The purchase of any travel 
                services offered by <b>AIRPROVE TRAVEL AGENCY</b> constitutes a contractual arrangement 
                between the Client and <b>AIRPROVE TRAVEL AGENCY</b> and represents the Client’s acceptance 
                of the <b>AIRPROVE TRAVEL AGENCY</b> Terms & Conditions set out herein.   
            </li>
        </ol>

        <ol class="list-decimal my-5 space-y-4">
            <li>
                <p class="text-lg font-semibold">ABOUT US</p>
                <ul class="list-disc ml-5">
                    <li>
                        <b>AIRPROVE TRAVEL AGENCY</b> is a Travel Agency in the Philippines, based in Naga City Province of Camarines Sur, Bicol Region. 
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">BOOKING CONDITIONS</p>
                <ul class="list-disc ml-5">
                    <li>
                        All travel arrangements should be booked at least within <b>THREE</b> business days in 
                        advance. For tour packages we strongly recommend that Clients book with a few weeks’ 
                        notice. For Group Tours we recommend that Clients book with a few months’ notice. 
                        Once Clients have confirmed and submitted his/her booking and personal travel details 
                        to <b>AIRPROVE TRAVEL AGENCY</b> he/she will receive a confirmation and pro-forma invoice of 
                        his/her booking via e-mail. Please note that <b>All bookings require a Non-refundable 
                        Down Payment of 50% of the total package price at the time of the booking. </b> If 
                        booking occurs less than 7 days before your arrival date <b>FULL payment</b> is required. 
                        If you prefer to pay for your booking with a bank transfer, please contact 
                        <b>AIRPROVE TRAVEL AGENCY</b>  through email 
                        <a href="mailto:inquiry.myairprove@gmail.com" class="hover:text-blue-500 text-blue-800 underline">
                            (inquiry.myairprove@gmail.com)
                        </a> and send us 
                        all the information regarding the booking, name of the guests, details of the tour, 
                        type of transportation, preferred accommodation, arrival date and deposit 
                        slip/confirmation. The travel voucher will be emailed to the Client once the 
                        <b>FULL payment</b> has been received. Client will receive the travel documents: 
                        e-tickets, vouchers and itinerary via email. Please print out these documents, 
                        as the Client will need to show them for all services during his/her visit to 
                        preferred destination. If by any chance our travel consultant is unable to obtain 
                        confirmation (if car rental/ or accommodation is not available) an alternative will 
                        be offered. If the Client does not accept the alternative the Client is entitled to 
                        cancel with a full refund of the deposit. <b>AIRPROVE TRAVEL AGENCY</b> reserves the right 
                        to cancel bookings when services are not available (e.g. when a car rental is not 
                        available, when accommodation is unavailable, etc.).Booking services with 
                        <b>AIRPROVE TRAVEL AGENCY</b>  is available only to persons aged <b>18 years and older</b> and that 
                        the Client has the right, authority and capacity to transact business  and agree to 
                        abide by this Agreement. If the client is using the Services on behalf of another 
                        organization or entity, then he/she agrees to be bound by this Agreement on behalf 
                        of that Organization and he/she represents and warrants that he/she has the authority 
                        to bind the Organization to this Agreement. By submitting a booking, Client warrants 
                        and confirms to <b>AIRPROVE TRAVEL AGENCY</b> that he/she complies with these arrangements. 
                        This Agreement is published in compliance with, and is governed by the provisions of 
                        Philippine law.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">TERMS OF PAYMENT</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        Please note that <b>All bookings require a Non-refundable Down Payment of 50% of the total package price 
                        at the time of the booking.</b> If the booking occurs less than 7 days before the Client’s arrival date 
                        <b>FULL payment</b> is required. We accept payment thru Bank Deposit, Debit/Credit Card payments. 
                        If you prefer to pay for your booking with a bank transfer, please contact us and send us all the 
                        information regarding the booking, name of the guests, details of tour, type of preferred transportation, 
                        accommodation, arrival date and scanned deposit slip. All Prices quoted on <b>AIRPROVE TRAVEL AGENCY</b>
                        are in <b>PHILIPPINE PESOS (PHP)</b> and per person, unless otherwise specified. All payments are due in 
                        <b>PHILIPPINE PESOS. AIRPROVE TRAVEL AGENCY</b>  is not responsible for any changes in currency exchange 
                        rates that might occur from the time of booking until a refund is made.
                    </li>

                    <li>
                        <p class="font-semibold">3.1 For Cash Payment Deposit through Banks</p>
                        <!-- TABLE 1 -->
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 border-2 border-solid rounded-lg">
                                <thead class="text-lg text-gray-700 uppercase bg-gray-300 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 rounded-s-lg">
                                            PHILIPPINE PESO ACCOUNT
                                        </th>
                                        <th scope="col" class="px-6 py-3"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BANK
                                        </th>
                                        <td class="px-6 py-4">
                                            SECURITY BANK
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BRANCH
                                        </th>
                                        <td class="px-6 py-4">
                                            BOCAUE BULACAN
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT TYPE
                                        </th>
                                        <td class="px-6 py-4">
                                            CHECKING
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NUMBER
                                        </th>
                                        <td class="px-6 py-4">
                                            0000 0580 49551
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NAME
                                        </th>
                                        <td class="px-6 py-4">
                                            Airprove Travel Agency
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- TABLE 2 -->
                            <!-- <table class="w-full text-sm text-left rtl:text-right text-gray-500 border-2 border-solid rounded-lg">
                                <thead class="text-lg text-gray-300 uppercase bg-gray-300 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 rounded-s-lg">PHILIPPINE PESO ACCOUNT </th>
                                        <th scope="col" class="px-6 py-3"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BANK
                                        </th>
                                        <td class="px-6 py-4">
                                            BANK OF THE PHILIPPINES ISLAND
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BRANCH
                                        </th>
                                        <td class="px-6 py-4">
                                            NAGA - PEÑAFRANCIA
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT TYPE
                                        </th>
                                        <td class="px-6 py-4">
                                            SAVINGS
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NUMBER
                                        </th>
                                        <td class="px-6 py-4">
                                            3929 1532 69
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NAME
                                        </th>
                                        <td class="px-6 py-4">
                                            JOSEPH LOUIE B. SOLCORETES
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->

                            <!-- TABLE 3 -->
                            <!-- <table class="w-full text-sm text-left rtl:text-right text-gray-500 border-2 border-solid rounded-lg">
                                <thead class="text-lg text-gray-300 uppercase bg-gray-300 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 rounded-s-lg">PHILIPPINE PESO ACCOUNT </th>
                                        <th scope="col" class="px-6 py-3"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BANK
                                        </th>
                                        <td class="px-6 py-4">
                                            PHILIPPINE NATIONAL BANK
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            BRANCH
                                        </th>
                                        <td class="px-6 py-4">
                                            QUEZON CITY - ETON
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT TYPE
                                        </th>
                                        <td class="px-6 py-4">
                                            CHECKING
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NUMBER
                                        </th>
                                        <td class="px-6 py-4">
                                            1227 7000 3576
                                        </td>
                                    </tr>
                                    <tr class="bg-white dark:bg-gray-800">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            ACCOUNT NAME
                                        </th>
                                        <td class="px-6 py-4">
                                            JOSEPH LOUIE B. SOLCORETES
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
                        </div>
                    </li>
                    <li>
                        <p class="font-semibold">3.2 For Credit/Debit Card Payments</p>
                        <p>
                            <b>AIRPROVE TRAVEL AGENCY</b> accepts debit/credit card payment using Maya Terminal, 
                            Maya Business Terms and Conditions may apply.
                        </p>
                    </li>
                    <li>
                        <p class="font-semibold">3.3 For Western Union</p>
                        <p>
                            With 245,000 Western Union Agent locations worldwide, it is easy to send and 
                            receive money. Choose to send money in minutes, the next day or directly to a 
                            bank account. Use cash, a credit card, or a debit card.
                        </p>
                    </li>
                    <li>
                        <p class="font-semibold">3.4 For Checks</p>
                        <p>
                            Checks may be accepted as payment for local travelers. Payments made by check are 
                            not considered “paid”, until fully cleared by our bank and released for payment. 
                            Thus, we require payment of Checks at least 7 days before the Client’s Travel Date.
                        </p>
                    </li>
                    <li>
                        <p class="font-semibold">3.5 For Remittance from other Banks from other Countries</p>
                        <p>
                            International bank transfer can be instantaneous, or it can take 5-7 business days. 
                            In some cases, transfers will take even longer than this, however around a week is a fairly 
                            standard time frame for many overseas transfers. Please take note that currency rates depends 
                            from one bank to another and varies each day. Each bank has its own tariff and the pricing is 
                            subject to change. Please check with your bank for the corresponding fees and remittance charges 
                            from the Sending and the Receiving Banks. Please take note that <b>AIRPROVE TRAVEL AGENCY  will base 
                            the Client’s payment from the ACTUAL PESO DEPOSIT to the Bank.</b> Remittance Fees from the Sending 
                            and Receiving Bank would be borne by the Client. International payments can be tricky. There is 
                            often confusion caused by use of multiple banks, different methods, and sometimes additional fees 
                            so please check with your bank. <b>It is only upon receipt of payment from AIRPROVE TRAVEL AGENCY’ 
                            bank that booking is confirmed. For more info about remittance processing, please check this 
                            site:</b> <a href="http://blog.asiaqualityfocus.com/overseas-banking-fees/" class="hover:text-blue-500 underline text-blue-800">http://blog.asiaqualityfocus.com/overseas-banking-fees/</a>
                        </p>
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">VALIDITY OF PRICES</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        All rates quoted or shown in our tariffs are based on current prices. 
                        <b>AIRPROVE TRAVEL AGENCY</b> reserves the right to alter or change already published 
                        prices on the website without notice in the event of currency fluctuation, 
                        government taxes, or any such costs increases that is outside 
                        <b>AIRPROVE TRAVEL AGENCY</b> control. <b>AIRPROVE TRAVEL AGENCY</b> reserves the right to 
                        alter routes, itineraries or departure times without prior notice should this 
                        be necessary due to weather or other reasons that are beyond <b>AIRPROVE TRAVEL AGENCY </b>
                        control. Please notice that in the case of changes of flights, the passenger is 
                        responsible for any additional costs that become payable as a result of such changes. 
                        Prices are guaranteed against any surcharge after an invoice has been issued, 
                        unless the increase is due to government laws or currency changes and amounts to 
                        5% of the package price or more. The amounts that exceed 5% will be charged and 
                        clients will be entitled to cancel their booking with a full refund if the surcharge 
                        exceeds 10%.  Any increase due to changes in government laws, i.e. tax changes will 
                        be added to the invoice.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">TRAVELLING WITH CHILDREN</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        If the Client is travelling with young children (from 2 years old to 11 years old), 
                        it is important to note that child seats are not provided for transfers and the 
                        legislation varies from one country to another. Please see the Travel Voucher issued 
                        for details. Free accommodation stays for children are generally based on sharing 
                        existing beds in the room. If the Client requires a separate bed, he/she must advise 
                        us at the time of booking, as there is a corresponding charge added to his/her booking.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">SPECIAL REQUESTS</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        For any special requests, please inform us in writing when booking is made. 
                        We are happy to pass your special requests on to the hotel, airline or other 
                        supplier but we cannot guarantee that it will be accommodated, however we will 
                        do our best given sufficient time of notice. Unless and until specifically confirmed 
                        all special requests are subject to availability.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">PASSENGERS WITH DISABILITIES</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        <b>AIRPROVE TRAVEL AGENCY</b> makes every reasonable effort to accommodate all passengers’ 
                        needs; however, our vehicles are not equipped with disability access, wheelchairs 
                        and other disability aids. We assume no responsibility for injury or any inconvenience 
                        in this regard. We cannot guarantee hotel rooms for our customers with disabilities 
                        either when booking and overnight trip with hotel accommodation included. However, 
                        we will do our very best to try to reserve the appropriate room, if available. 
                        Passengers with disabilities and/or any other health issues must advise and inform 
                        <b>AIRPROVE TRAVEL AGENCY</b> by email at the time of making the reservation. 
                        We reserve the right to decline a booking should it be determined that we are unable 
                        to properly accommodate or meet our obligations towards that passenger(s) and or 
                        for safety considerations.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">LOCAL PURCHASE</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        We cannot accept responsibility for any items that the Client may purchase locally 
                        i.e.: jewelry, food, souvenir items  etc. and the quality and value of such cannot 
                        be guaranteed. We recommend that the Client check whether or not any extra charges 
                        will be payable for import duty or freight and we are unable to assist with any costs 
                        that the Client may incur in this respect.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">CANCELLATION TERMS/REFUNDS</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        <b>Once booking is confirmed and finalized, the Tour Package and All Land Arrangement bookings 
                        and the 50% down payment are Non-Refundable. There is No Refund for any unused tours, transport,
                         hotels. However, in the event of natural calamities and force majeure, or mandated government 
                         cancellations, Clients may be entitled for a refund, subject to the Supplier’s (ex. Hotel) 
                         Terms and Conditions.</b> If the Client’s Travel Package includes international or domestic air tickets, 
                         cancellation of airline ticket will be based on its cancellation policy, based on their fare rules. 
                         <b>AIRPROVE TRAVEL AGENCY</b> only acts as an agent for the airlines and is not responsible if the 
                         said Airline cancels, reschedules, or delays a flight for any reason. In any case, 
                         <b>AIRPROVE TRAVEL AGENCY</b> will extend necessary assistance to address concerns of guests 
                         and to safeguard their welfare, but the Airline Company will be responsible for the final 
                         decision on such requests. Additional and incidental expenses incurred due to delay, suspension, 
                         accidents and local conditions must be borne by the guest.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">FORCE MAJEURE</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        World weather is becoming more erratic and unpredictable and we cannot be held responsible for 
                        disruption to your holiday due to bad or unusual weather conditions, Please take note that 
                        <b>AIRPROVE TRAVEL AGENCY</b> is not responsible for changes which arise as a result of events 
                        outside of our control, such as technical or maintenance problems with transportation, 
                        changes imposed by re-scheduling or cancellation of flights by an airline, or main charter 
                        company, war or threat of war, riot, civil strife, industrial disputes, natural disasters, 
                        bad weather or terrorist activity and its consequences, natural or nuclear disaster, fire, 
                        adverse weather conditions, epidemics and pandemics.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">LIABILITY</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        Once the Client book through <b>AIRPROVE TRAVEL AGENCY</b>, he/she agrees to be legally bound by the following terms and conditions. <b>AIRPROVE TRAVEL AGENCY</b> acts in good faith, acting solely as an intermediary and an agent for vendors and service providers (i.e. Air and Sea Carriage, Hotel accommodation, Ground transportation, Tours, Meals, Cruises, Travel Insurance, etc.) and as such, it is not liable for breach of contract or any intentional or careless actions or omissions on part of suppliers, which may result in any loss, damage, delay, injury, death, sickness, or accident sustained by the client and his travel companions in the course of availing the indicated services. This agency likewise shall not be responsible for any injuries, damages, losses caused to any traveler in connection with terrorist activities, social or labor unrest, mechanical or construction difficulties, diseases, local laws, climatic conditions, schedule changes caused by weather or road conditions, force majeure such as natural catastrophes, strikes, abnormal conditions or developments, or any other irregularities, actions, omissions or conditions outside the travel agent’s reasonable control. By embarking upon this travel, Travelers (CLIENT/CUSTOMER) voluntarily assume all risks involved with such travel, whether expected or unexpected. <b>Luggage is at the owner’s risk throughout the tour.</b> The fees for the use of porters are shouldered by the clients. All information on <b>AIRPROVE TRAVEL AGENCY</b> website is subject to change and we reserve the right to correct any mistakes that may appear on our website. These general booking conditions are subject to change and we reserve the right to update these conditions without notice.
                        <b>AIRPROVE TRAVEL AGENCY</b>  <u><b>reserves the right to refuse service to anybody.</b></u> Every passenger on board the vehicle must respect the tour guide and the time frame in which a tour is scheduled to occur when you stop for photos, breaks, hiking, walking or visiting a site of interest. <b>AIRPROVE TRAVEL AGENCY</b>  is not responsible for any delays caused by the customer or individual who does not comply with the time limit a tour guide recommends for each stop or site. Please note that overtime fees may apply if applicable. <b>AIRPROVE TRAVEL AGENCY</b>  reserves the right to remove any passenger who causes a disturbance to any individual in the vehicle, including the tour guide/driver, or poses as a threat to an otherwise smooth and safe trip.
                        Some tours may include physical activities like hiking, walking, kayaking, riding an ATV (all-terrain vehicle), rafting, riding in vehicles, which involves risks. <b>AIRPROVE TRAVEL AGENCY</b>  does not assume any responsibility for any injuries, accidents or health issues that might be a result from any of these physical activities. All of our customers are responsible for evaluating the potential risks of participating in any of our tours that may involve physical activity. Please immediately inform <b>AIRPROVE TRAVEL AGENCY</b> if Client have any health issues prior to making his/her reservation.
                        Flight cancellations and delays of airlines are beyond <b>AIRPROVE TRAVEL AGENCY</b> reasonable control, hence, it accepts no responsibility over these events. In the event of any delay or suspension, it is the responsibility of the Airline Company to take the necessary remedial action to address the same and its consequences. In any case, <b>AIRPROVE TRAVEL AGENCY</b> will extend necessary assistance to address concerns of guests and to safeguard their welfare, but the Airline Company will be responsible for the final decision on such requests. Additional and incidental expenses incurred due to delay, suspension, accidents and local conditions must be borne by the guest.
                        Please be advised that it is the Client’s responsibility to arrange proper exit/re-entry documents, validity of passport and visa for the country (ies) to visit. Given the possibility of changes to timetables, routings, programs, and (tour) prices, the same shall be subject to confirmation
                        <b>Unused Tour Services are NON REFUNDABLE.</b> Whether you avail of the day tours or not, client would still have to pay for it since this is what is in the travel agreement. All Confirmed Bookings are already considered as <b>GUARANTEED Bookings</b> and as such are considered <b>NON REFUNDABLE.</b>
                        <br><br>Customers’ retention of tickets, reservations, tour/hotel vouchers and other travel documents after issuance shall constitute consent to all of the above and an agreement on their part to convey hereto to their travel companions or group members.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">DOCUMENTATION</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        Where travel and health documents are necessary to comply with the requirements of the Philippines Islands 
                        you may wish to visit, then it is YOUR RESPONSIBILITY to procure them. If failure to obtain any such documents 
                        results in fines, surcharges or any other financial penalty being imposed upon us, then you agree to reimburse 
                        us accordingly. You must ascertain by consulting your own doctor, if necessary the specific precautions deemed 
                        prudent for the country or destination you intend to visit and to ensure that the appropriate medication, 
                        inoculations or other precautions are taken.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">CONDITIONS OF CARRIAGE AND ACCOMMODATIONS</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        <b>AIRPROVE TRAVEL AGENCY</b> is neither a carrier nor a provider of accommodations. Each journey (whether undertaken or not) that the Client booked by land, sea or air is governed by the conditions of the carrier undertaking to provide that carriage. Some of these conditions limit or exclude liability and are often the subject of international agreements. Copies of the applicable agreements are available for inspection at the offices of the carrier concerned. It is the Client’s responsibility to re-confirm the onward or return sectors of any air journey with the carrier concerned and according to such carrier’s terms and conditions and respective regulations. When Client book accommodations (whether provided or not), its availability or provision is subject to the ‘house rules’ of the hotel or other accommodation providing or undertaking to provide such accommodations. <b>Termination</b> We reserve the right in our absolute discretion to terminate the client’s travel arrangements without notice should the said client’s behavior be such that it is likely in our opinion, in violation of any applicable governing laws, causes distress, damage, annoyance, or danger to <b>AIRPROVE TRAVEL AGENCY</b>, or any of its staff , fellow travelers or any other person. In such circumstances, no refund or compensation will be due to the client. Clients should be guided by the Hotel’s Check-in and Check-out policies. Each hotel varies in their check-in and check-out time. However, the <b>standard hotel check-in is at 2:00 PM while check-out is at 12:00 PM (Noon). Early Check-in is SUBJECT TO AVAILABILTY and Hotel Surcharge.</b>
                        <br>
                        <b>Damage and Loss of Hotel Key Cards, Bed sheets and Towels, and Consumption of Food and Drinks in the Mini-fridge-Bar and other Incidental Expenses  are NOT included in the Tour/Travel Package and these are charged to the Client.</b>
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">TOUR GUIDES</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        <b>Clients should take note that Tour Guides and Tour Facilitators are NOT the personal maids of the guests/clients.</b>
                        A <b>tour guide</b> (U.S.) or a <b>tourist guide</b> (European) guides visitors in the language of their choice,
                        provides assistance, information and cultural, historical and contemporary heritage interpretation
                        to people on organized tours and individual clients at educational establishments, religious and 
                        historical sites, museums, and at venues of other significant interest. As such, Clients should not 
                        expect that the tour guides would personally assist to their every needs. Carrying of Luggage are the
                        responsibility of the clients. During meals, the tour guides will seat in the table next to the clients.
                        If clients do not wish to sit beside the tour guide, please advise in advance so that we could make proper
                        arrangements. Furthermore, a tour guide is also a human person who deserves some respect. Thus, 
                        we advise our clients to treat our tour guides with proper respect and decency. If clients need 
                        to reprimand the tour guide, please do so in a decent manner, otherwise, we would be constrained 
                        to cancel your tour without any refund.
                        
                        <ul class="list-disc ml-5 mt-5">
                            <li>
                                <u><b>We reserve the right to refuse service to any person or business who may refuse to comply with our safety instructions,</b></u>
                                ,our tour itinerary, or to anyone who may pose a threat to our customers and/or our tour guide-drivers. <b>Screaming at or 
                                insulting tour guides/drivers or fellow passengers or third party businesses and subcontractors is NOT ACCEPTABLE under 
                                any circumstances and will terminate and cancel your tour without any refund. Discrimination against a customer or a 
                                tour guide/driver for any reason is not acceptable either under any circumstance.</b>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">ISUANCE</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        Please note that individual travel insurance is not included in the tour price and all medical costs and other costs involved must be paid for by the client. It is therefore strongly recommended that participants take out personal travel insurance, especially for the active tours. We strongly recommend to all of our clients to purchase comprehensive travel insurance prior to departure to meet any contingencies that would cover against cancellation charges, unexpected curtailment of your vacation, medical expenses arising overseas including repatriation, loss or damage to luggage and personal liability claims against you. Should you fail to secure such Travel Insurance coverage prior to your travel, then you accept full responsibility for yourself and all of the members of your party, and indemnify <b>AIRPROVE TRAVEL AGENCY</b> and our overseas Philippine Travel agents and representatives (as applicable) for any and all costs that may arise, which would otherwise have been met, had such travel insurance been in force.
                    </li>
                </ul>
            </li>
            <li>
                <p class="text-lg font-semibold">COMPLAINTS</p>
                <ul class="list-disc ml-5 space-y-4">
                    <li>
                        Our main goal is to provide our Clients with excellent services. If our Clients, for any reason, are not 
                        satisfied with our holiday package, we would like to hear about it. We recommend our Client to contact 
                        us as soon as any incident occurs. All complaints must reach <b>AIRPROVE TRAVEL AGENCY</b> within 10 days after 
                        Client’s departure. Otherwise, any possible compensation entitlement will not be valid. Please call 
                        <b>AIRPROVE TRAVEL AGENCY</b> at +63 967 164 3779 (Globe), from wherever Client may be or email us at 
                        inquiry.myairprove@gmail.com. If a problem arises during Client’s trip, please contact us immediately, 
                        so that we can assist you. All notices and communications shall be in writing, in English and shall 
                        deemed given if delivered personally or by commercial messenger or courier service, or mailed by 
                        registered or certified mail (return receipt requested) or sent via email/ facsimile, with due 
                        acknowledgment of complete transmission to the following 
                        address: <b>AIRPROVE TRAVEL AGENCY  16-4 SAN JUAN ST. PEÑAFRANCIA AVENUE, NAGA CITY 4400.</b>

                        <br>

                        <p class="text-sm mt-5">
                            Any contract or dispute between us and these Reservations conditions are to be governed by and construed in accordance with Philippines Law. Both parties agree to submit to the exclusive jurisdiction of the Courts of Legazpi City, Philippines.
                            The effective date of this policy is January 1, 2016. It replaces all prior privacy policies issued by <b>AIRPROVE TRAVEL AGENCY.</b> We reserve the right to change our policy at any time. Visitors to this Travel Website should review our policies from time to time to stay abreast of changes
                        </p>
                    </li>
                </ul>
            </li>
        </ol>

        <p class="font-semibold text-xl">PLEASE TAKE NOTE:</p>
        <ul class="list-disc text-sm">
            <li>
                <b>AIRPROVE TRAVEL AGENCY</b> reserves the right to hire independent tour guide/drivers, subcontractors 
                and/or contractors to facilitate our tours, shuttle services and travel packages.
            </li>
            <li>
                In the event that we are unable to facilitate your tour due to weather and/or road conditions, we will attempt to reach each location, site and destination using alternative routes but we are not liable nor responsible for these unforeseen weather/traffic conditions. No refunds will be available.
            </li>
            <li>
                All photos or videos taken by our staff of our customers or passengers, during the capacity of the tour, trip or transfer, may be included on our website. Customers hereby agree to the use of these photos and videos and under no circumstances can customers dispute.
            </li>
            <li>
                <b>
                    No substitution or refunds for any unused part of the tour(s) will be issued. All sales are final.
                </b>
            </li>
            <li>
                <b>
                    No refunds will be issued for a no-show or unused portion of the tour.
                </b>
            </li>
            <li>
                Tour guide/driver gratuities are not included in our tour price unless indicated on your tour voucher or email confirmation.
            </li>
            <li>
                Tour prices do not include additional incidental fees, unless otherwise indicated on your tour voucher or email confirmation.
            </li>
            <li>
                All vehicles operated by AIRPROVE TRAVEL AGENCY are non-smoking according to Municipal and City Ordinances.
            </li>
            <li>
                <u><b>We reserve the right to refuse service to any person or business who may refuse to comply with our safety instructions,</b></u>
                our tour itinerary, or to anyone who may pose a threat to our customers and/or our tour guides/drivers. 
                <b>Screaming at or insulting tour guide-drivers or fellow passengers or third party businesses and subcontractors 
                is NOT ACCEPTABLE under any circumstances and will terminate and cancel your tour without any refund. Discrimination 
                against a customer or a tour guide–driver for any reason is not acceptable either under any circumstance.</b>
            </li>
        </ul>
    </div>
    <PageFooter/>
</template>

<script>
import NavbarComp from '@/components/NavbarCompSecondary.vue'
import PageFooter from '@/components/PageFooter.vue'
export default{
    components: { NavbarComp, PageFooter },
}
</script>