<template>
    <footer id="contact" class="bg-white border-t-2 border-gray-200">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
                <a href="https://flowbite.com/" class="flex items-center">
                    <img src="../assets/company_logo/Airprove-blue_logo-NOBG.png" class="w-16 h-16 me-3" alt="AirProve Logo" />
                    <span class="self-center text-2xl font-semibold whitespace-nowrap">AirProve</span>
                </a>
                <p class="text-xl tracking-wider">Explore You!</p>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Resources</h2>
                    <ul class="text-gray-500 font-medium">
                        <li class="mb-4">
                            <a @click="triggerCustomer" class="hover:underline hover:cursor-pointer">Customer Services</a>
                        </li>
                        <li class="mb-4">
                            <a @click="triggerContact" class="hover:underline hover:cursor-pointer">Contact Us</a>
                        </li>
                        <li class="mb-4">
                            <a @click="triggerFaqs" class="hover:underline hover:cursor-pointer">FAQs</a>
                        </li>
                        <li class="mb-4">
                            <a @click="triggerHelp" class="hover:underline hover:cursor-pointer">Help Center</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Follow us</h2>
                    <ul class="text-gray-500 font-medium">
                        <li class="mb-4">
                            <a href="https://www.facebook.com/airprove.travelagency" class="hover:underline ">Facebook</a>
                        </li>
                        <li class="mb-4">
                            <a href="https://www.instagram.com/myairprove?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" class="hover:underline">Instagram</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:underline">Twitter</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:underline">Tiktok</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Legal</h2>
                    <ul class="text-gray-500 font-medium">
                        <li class="mb-4">
                            <a @click="triggerPrivacy" class="hover:underline hover:cursor-pointer">Privacy Policy</a>
                        </li>
                        <li>
                            <a @click="triggerTerms" class="hover:underline hover:cursor-pointer">Terms &amp; Conditions
                            </a>
                        </li>
                        <!-- <li>
                            <a @click="goToAdmin" class="hover:underline hover:cursor-pointer">Admin</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-6" />
        <p class="mb-2 text-md font-semibold">Where to find us?</p>
        <iframe class="w-full mb-5" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15509.786837423393!2d123.1928981!3d13.6305691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6743ed5afa664daf%3A0x3ca238c92e1e4573!2sAirProve%20Travel%20Agency!5e0!3m2!1sen!2sph!4v1706409372494!5m2!1sen!2sph" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm text-gray-500 sm:text-center">© 2023 <a href="https://flowbite.com/" class="hover:underline">AirProve™</a>. All Rights Reserved.
            </span>
            <div class="flex mt-4 sm:justify-center sm:mt-0">
                <a href="https://www.facebook.com/airprove.travelagency" class="text-gray-500 hover:text-gray-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                            <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                        </svg>
                    <span class="sr-only">Facebook page</span>
                </a>
                <a href="https://www.instagram.com/myairprove?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" class="text-gray-500 hover:text-gray-900 ms-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                    <span class="sr-only">Instagram page</span>
                </a>
                <a href="#" class="text-gray-500 hover:text-gray-900 ms-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                    </svg>
                    <span class="sr-only">Twitter page</span>
                </a>
                <a href="#" class="text-gray-500 hover:text-gray-900 ms-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                    </svg>
                    <span class="sr-only">Tiktok page</span>
                </a>
            </div>
        </div>
        </div>
    </footer>

    <!-- Terms and Conditions -->
    <!-- <div v-if="terms === true">
        <PopUpModal>
            
            <div class="relative bg-white">
                
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Terms & Conditions
                    </h3>
                    <button @click="triggerTerms" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                
                <div class="p-4 md:p-5 space-y-4">
                    <p class="text-base leading-relaxed text-gray-500">
                        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                    </p>
                    <p class="text-base leading-relaxed text-gray-500">
                        The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                    </p>
                </div>
            </div>
        </PopUpModal>
    </div> -->
    <!-- End -->

    <!-- Privacy Policy -->
    <div v-if="privacy === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Privacy Policy
                    </h3>
                    <button @click="triggerPrivacy" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4 overflow-y-auto h-96">
                    <p class="text-base leading-relaxed text-gray-500">
                        Thank you for choosing AirProve Travel Agency for your travel needs. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information. By using our services, you agree to the terms outlined in this Privacy Policy.
                    </p>
                    <!-- <p class="text-base leading-relaxed text-gray-500">
                        The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                    </p> -->
                    <ul class="list-decimal text-gray-500">
                        <li>
                            <p class="font-semibold">Information We Collect:</p>
                            We may collect your name, contact details, payment information, and other relevant details necessary for booking and providing travel services.
                        </li>
                        <li>
                            <p class="font-semibold">Usage Information:</p>
                            We gather information about your interactions with our website and services, including IP addresses, device information, and browsing patterns.
                        </li>
                    </ul>
                    <p class="text-gray-500 font-semibold">How We Use Your Information:</p>
                    <ul class="list-decimal text-gray-500">
                        <li>
                            <p class="font-semibold">Booking and Travel Services:</p>
                            We use your personal information to facilitate travel bookings, process payments, and provide the services you request.
                        </li>
                        <li>
                            <p class="font-semibold">Communication:</p>
                            We may use your contact information to communicate with you about your bookings, updates, and promotional offers. You can opt-out of marketing communications at any time.
                        </li>
                        <li>
                            <p class="font-semibold">Improving Services:</p>
                            We analyze usage data to enhance our website, services, and user experience.
                        </li>
                    </ul>
                    <ul class="text-gray-500">
                        <li class="mb-2">
                            <p class="font-semibold">Data Security:</p>
                            We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
                        </li>
                        <li class="mb-2">
                            <p class="font-semibold">Sharing of Information:</p>
                            We do not sell, trade, or rent your personal information to third parties. We may share information with our service providers solely for the purpose of delivering the requested services.
                        </li>
                        <li class="mb-2">
                            <p class="font-semibold">Your Choices:</p>
                            You have the right to access, correct, or delete your personal information. To exercise these rights or for any privacy-related inquiries, contact us at <a href="mailto:inqury.myairprove@gmail.com" class="text-blue-500 hover:text-blue-700">inqury.myairprove@gmail.com</a>.
                        </li>
                        <li class="mb-2">
                            <p class="font-semibold">Changes to the Privacy Policy:</p>
                            <p>
                                We reserve the right to update our Privacy Policy. Any changes will be effective upon posting on our website. 
                                By using AirProve Travel Agency's services, you consent to the terms outlined in this Privacy Policy.
                                Thank you for choosing AirProve Travel Agency. Your trust is our priority.
                            </p>
                            <p class="mt-2 font-semibold">AirProve Travel Agency</p>
                        </li>
                    </ul>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->

    <!-- Customer Services -->
    <div v-if="customer_services === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Customer Services
                    </h3>
                    <button @click="triggerCustomer" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <p class="text-base leading-relaxed text-gray-500">
                        Our dedicated customer support team is here to assist you. Please find below the details to reach our customer service:
                    </p>

                    <div class="text-base leading-relaxed text-gray-500">
                        <h3 class="font-semibold">Customer Support Hours:</h3>
                        <p>Monday to Saturday: 9:00 AM - 6:00 PM</p>
                        <p>Sunday: 1:00 PM - 6:00 PM</p>
                        <br>
                        <p>We're located at: <b>16-4 San Juan St. Penafrancia Avenue, Naga City Philippines 4400</b></p>
                        <iframe class="w-full mb-5" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15509.786837423393!2d123.1928981!3d13.6305691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6743ed5afa664daf%3A0x3ca238c92e1e4573!2sAirProve%20Travel%20Agency!5e0!3m2!1sen!2sph!4v1706409372494!5m2!1sen!2sph" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->

    <!-- Contact Us -->
    <div v-if="contact_us === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Contact Us
                    </h3>
                    <button @click="triggerContact" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <p class="text-base leading-relaxed text-gray-500">
                        Feel free to reach out to us with any questions or inquiries. We are here to help!
                    </p>
                    <div class="text-base leading-relaxed text-gray-500">
                        <h3 class="text-lg">By Phone:</h3>
                        <p>Call us at: <strong>(+63) 915-032-4389</strong></p>
                        <br>
                        <h3 class="text-lg">By Email:</h3>
                        <p>Email us at: <strong><a href="mailto:inqury.myairprove@gmail.com" class="text-blue-500 hover:text-blue-700">inqury.myairprove@gmail.com</a></strong></p>
                    </div>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->

    <!-- FAQs -->
    <div v-if="faqs === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Frequently Asked Questions (FAQs)
                    </h3>
                    <button @click="triggerFaqs" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4 overflow-y-auto h-96">
                    <ol class="list-decimal text-gray-500 text-justify">
                        <li>
                            <p class="font-semibold">What is Airprove?</p>
                            Airprove is a dynamic travel agency dedicated to providing seamless travel experiences to our customers. We specialize in offering a wide range of travel services, including flight bookings, hotel reservations, car rentals, vacation packages, and more.
                        </li>
                        <li>
                            <p class="font-semibold">How can I book a flight with Airprove?</p>
                            Booking a flight with Airprove is easy! You can visit our website, call our customer service hotline, or visit one of our physical locations to speak with one of our travel experts. Simply provide your travel details, and we'll help you find the best flight options tailored to your preferences and budget.
                        </li>
                        <li>
                            <p class="font-semibold">What destinations does Airprove offer flights to?</p>
                            Airprove offers flights to a diverse range of domestic and international destinations. Whether you're planning a weekend getaway or a long-haul journey, we can assist you in finding flights to virtually any location worldwide.
                        </li>
                        <li>
                            <p class="font-semibold">Can Airprove help with hotel accommodations?</p>
                            Absolutely! In addition to flight bookings, Airprove offers comprehensive travel services, including hotel accommodations. We partner with leading hotels to provide our customers with a wide selection of options to choose from.
                        </li>
                        <li>
                            <p class="font-semibold">Are vacation packages available through Airprove?</p>
                            Yes, Airprove offers customizable vacation packages tailored to suit your preferences and budget. Whether you're dreaming of a relaxing beach vacation, an adventurous mountain retreat, or a cultural city tour, we can help you create the perfect vacation package.
                        </li>
                        <li>
                            <p class="font-semibold">What payment methods does Airprove accept?</p>
                            Airprove accepts various payment methods, including credit cards, debit cards, bank transfers, and online payment platforms. Our goal is to make the booking process as convenient and secure as possible for our customers.
                        </li>
                        <li>
                            <p class="font-semibold">Is travel insurance available through Airprove?</p>
                            Yes, Airprove offers travel insurance options to provide our customers with added peace of mind during their travels. Our travel insurance plans typically cover trip cancellations, medical emergencies, lost luggage, and other unforeseen circumstances.
                        </li>
                        <li>
                            <p class="font-semibold">How can I contact Airprove customer support?</p>
                            Our dedicated customer support team is available to assist you with any inquiries or concerns you may have. You can reach us by phone, email, or through our website's live chat feature. We're here to ensure that your travel experience with Airprove is smooth and hassle-free.
                        </li>
                        <li>
                            <p class="font-semibold">Does Airprove offer any loyalty or rewards programs?</p>
                            Yes, Airprove values customer loyalty and offers various rewards and loyalty programs for frequent travelers. By joining our loyalty program, you can earn points, enjoy exclusive discounts, and access special perks with each booking.
                        </li>
                        <li>
                            <p class="font-semibold">Is Airprove committed to sustainable travel practices?</p>
                            Yes, Airprove is committed to promoting sustainable travel practices and minimizing our environmental impact. We actively seek out eco-friendly travel options and partner with suppliers who share our commitment to sustainability.
                        </li>
                    </ol>
                    <ul class="text-gray-500">
                        <li class="mb-2">
                            <p>
                                We hope these FAQs provide helpful information about Airprove and our services. If you have any further questions or require assistance, please don't hesitate to contact us. Safe travels!
                            </p>
                            <p class="mt-2 font-semibold">AirProve Travel Agency</p>
                        </li>
                    </ul>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->

    <!-- Help center -->
    <div v-if="help === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Help Center
                    </h3>
                    <button @click="triggerHelp" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <p class="text-base leading-relaxed text-gray-500">
                        Visit us at our office located at <b>16-4 San Juan St. Penafrancia Avenue, Naga City Philippines 4400</b>.
                    </p>
                    <iframe class="w-full mb-5" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15509.786837423393!2d123.1928981!3d13.6305691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6743ed5afa664daf%3A0x3ca238c92e1e4573!2sAirProve%20Travel%20Agency!5e0!3m2!1sen!2sph!4v1706409372494!5m2!1sen!2sph" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->
</template>

<script>
import PopUpModal from '@/components/PopUpModal.vue'

export default{
    components: { PopUpModal },
    data(){
        return{
            terms: false,
            privacy: false,
            customer_services: false,
            contact_us: false,
            faqs: false,
            help: false,
        }
    },

    methods: {
        triggerTerms(){
            // this.terms = !this.terms;
            try{
                this.$router.push({name: 'terms-page'});
            } catch(error){
                console.log(error);
            }
        },

        triggerPrivacy(){
            this.privacy = !this.privacy
        },

        triggerCustomer(){
            this.customer_services = !this.customer_services;
        },

        triggerContact(){
            this.contact_us = !this.contact_us;
        },

        triggerFaqs(){
            this.faqs = !this.faqs;
        },

        triggerHelp(){
            this.help = !this.help;
        }
        // goToAdmin(){
        //     this.$router.push({name: 'admin-home'});
        // }
    }
}
</script>