<template>
    <AdminNav  class="absolute inset-x-0 top-0 z-50"/>
    <div class="relative">
        <div class="relative min-h-screen min-w-screen bg-cover bg-center brightness-75" style="background-image: url('https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D');">
            
        </div>
        
        <div class="absolute inset-0 flex flex-col items-center justify-center text-center">
            <div class="flex items-center justify-center h-screen w-auto">
                <form @submit.prevent="submitForm" class="bg-opacity-50 bg-blur-md backdrop-filter backdrop-blur-md backdrop-saturate-150 shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 w-auto lg:w-96 border border-lg">
                    <p class="text-2xl mb-4 sm:text-md text-white">Welcome Admin</p>
                    <p class="text-md mb-8 sm:text-md text-white">Enter your credentials to continue</p>
                    <div class="mb-6">
                        <label class="block text-white text-sm font-bold mb-2" for="username">
                            Username
                        </label>
                        <input
                            v-model="username"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Enter your username"/>
                    </div>
                    <div class="mb-8">
                        <label class="block text-white text-sm font-bold mb-2" for="password">
                            Password
                        </label>
                        <input
                            v-model="password"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="********"/>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="submit" class="login-user bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            <p v-if="btn_loading == false">Sign In</p>
                            <p v-else>
                                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                            </p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div v-if="error_acc === true">
        <PopUpModal>
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5">
                    <h3 class="text-xl font-semibold text-red-500">
                        Invalid username or password!
                    </h3>
                    <button @click="closeError" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
            </div>
        </PopUpModal>
    </div>
    <!-- End -->
</template>

<script>
import AdminNav from '../components/AdminNav.vue'
import Parse from 'parse';
import PopUpModal from '../components/PopUpModal.vue'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('A9iU0asj7lBx8THtL3OuHVdKAebIUED9GJKzrpWn', 'AQmS27wK0tZHTPFga30Afa0eMTqnBmzf7zk9yX4F');

export default{
    components: { AdminNav, PopUpModal },
    data(){
        return{
            username: '',
            password: '',
            error_acc: false,

            btn_loading: false,
        }
    },

    methods:{
        async submitForm() {
           Parse.User.logIn(this.username, this.password).then((user) => {
            console.log("User logged in: ", user);
                this.$router.push({name: 'admin-home'});
                this.btn_loading = false;
                this.getUserSessions();
           }, (error) => {
            console.log("Error logging in: ", error.message);
            // this.error_acc = true;
            this.popUp();
           })
        },

        popUp(){
            Swal.fire({
                icon: 'error', title: "Invalid username or password.",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
        },

        closeError(){
            this.error_acc = false;
        },
        getUserSessions(){
            const Session = Parse.Object.extend('_Session');
            const query = new Parse.Query(Session);

            query.equalTo('user', Parse.User.current());
            query.find().then(
                (sessions) => {
                    console.log('User sessions: ', sessions);
                }, (error) => {
                    console.error('Error querying sessions', error);
                }
            )
            this.btn_loading = true;
        }
    },

    mounted: async function(){
        // const user = new Parse.User();
        // user.set("username", "justin_admin");
        // user.set("password", "MyAirProveAdmin_2024");
        // user.set("email", "jberdin.myairprove@gmail.com");
        // user.set("isAdmin", true);
        // // other fields can be set just like with Parse.Object
        // user.set("phone", "09770427184");
        // try {
        // await user.signUp();
        // // Hooray! Let them use the app now.
        // } catch (error) {
        // // Show the error message somewhere and let the user try again.
        //     alert("Error: " + error.code + " " + error.message);
        // }
    }
}
</script>

<style>
.login-user{
    background-color: #1C3F60;
}

.login-user:hover{
    background-color: #2a5c8b;
}
</style>