<template>
    <NavbarCompSecondary/>
    <FlightSearch/>
    <PageFooter/>
</template>

<script>
import FlightSearch from '@/components/FlightSearch.vue'
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import PageFooter from '@/components/PageFooter.vue'

export default{
    components: { FlightSearch, NavbarCompSecondary, PageFooter },
    data(){
        return{
            departure_date: '',
            return_date: '',
            from_loc: '',
            to_loc: '',
        };
    },
    // computed:{
    //     watcher_(){
    //         return this.local_loc();
    //         return this.desti_loc();
    //         return this.depart_date();
    //         returnthis.ret_date();
    //         console.log(this.local_loc());
    //         console.log(this.desti_loc());
    //         console.log(this.depart_date());
    //         console.log(this.ret_date());
    //     },
    // },

    // mounted: async function(){
    //     this.auto_click();
    // },
    
    methods:{
        local_loc(){
            this.from_loc = this.$route.param.fromLoc;
            console.log("From: ",this.from_loc); 
        },

        desti_loc(){
            this.to_loc = this.$route.param.toLoc;
            console.log("To: ", this.to_loc);
        },

        depart_date(){
            this.departure_date = this.$route.param.departureDate;
            console.log("Departure: ",this.departure_date);
        },

        ret_date(){
            this.return_date = this.$route.param.returnDate;
            console.log("Return: ",this.return_date);
        },

        auto_click(){
            this.local_loc();
            this.desti_loc();
            this.depart_date();
            this.ret_date();
        },
    },
}

</script>