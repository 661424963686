<template>
    <NavbarCompSecondary/>
    <div class="bg-yellow-400">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <ServicesSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Our Services</h5>
                            <h5 class="mb-3 text-xl font-bold tracking-tight text-white text-center">We Provide this Travel Solutions</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <p class="text-4xl text-center mb-10 font-bold" style="color: #1C3F60">Discover the Key Features</p>
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/premium-vector/young-man-with-luggage-planning-sightseeing-route-attractions_701961-951.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Trip Planning and Customization</div>
                            <p class="text-gray-700 text-base">
                                At AirProve, we specialize in tailoring unforgettable journeys just for 
                                you. Picture this – a vacation perfectly aligned with your preferences, interests, 
                                and budget. We take the time to understand your travel goals, suggest ideal 
                                destinations, and meticulously plan every detail, from cozy accommodations to 
                                seamless transportation and captivating activities. Our goal is to make each 
                                trip uniquely yours, ensuring an experience that's not just a vacation but a 
                                personalized adventure you'll cherish.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/premium-vector/young-loving-couple-spending-morning-cozy-bed_82574-10826.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Transportation and Accommodation Booking</div>
                            <p class="text-gray-700 text-base">
                                Booking your travel has never been easier! As your dedicated travel partner, 
                                we handle the nitty-gritty details. Imagine a seamless journey where flights, 
                                ground transportation, and cozy accommodations are effortlessly arranged. 
                                Thanks to our extensive network, we secure competitive rates, saving you time 
                                and effort. Your focus? Enjoying the anticipation of your upcoming adventure 
                                while we take care of the rest.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover object-top h-48 w-96" src="https://img.freepik.com/premium-vector/woman-receiving-consultation-from-specialist-flat-illustration_181313-918.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Travel Consultation and Expert Advice</div>
                            <p class="text-gray-700 text-base">
                                When you travel with us, you're not just booking a trip – you're gaining a 
                                wealth of expertise. Our experienced travel agents are your go-to resource 
                                for insights into destinations, the latest travel trends, and cultural nuances. 
                                Need advice on visa requirements or safety considerations? We've got you covered. 
                                We ensure you have a comprehensive understanding of your chosen destination, 
                                empowering you to make informed decisions that align perfectly with your 
                                preferences and expectations. Because a well-informed traveler is a happy traveler!
                            </p>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>
    </div>
    <PageFooter/>
</template>

<script>
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import ServicesSVG from '@/components/ServicesSVG.vue'
import PageFooter from '@/components/PageFooter.vue'

export default{
    components: { NavbarCompSecondary, ServicesSVG, PageFooter }
}

</script>