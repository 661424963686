<template>
    <nav class="bg-white border-gray-200">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="https://myairprove.com" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="../assets/company_logo/Airprove-blue_logo-NOBG.png" class="w-16 h-16" alt="AirProve Logo" />
            <span class="self-center text-2xl text-slate-900 font-semibold whitespace-nowrap">AirProve</span>
        </a>
      </div>
    </nav>
</template>

<script>

export default{
  data(){
    return {
      open: false,
    }
  },
  methods:{
    goBack(){
      this.$router.push({name: 'Home'});
    },

    servicesPage(){
      this.$router.push({name: 'services'})
    },

    pricingPage(){
      this.$router.push({name: 'pricing'})
    },

    toggle(){
      this.open = !this.open
    }
  }
}
</script>