<template>
    <Navbar/>
    <div class="flex justify-center items-center">
        <PageNotFound/>
    </div>
    <div class="text-center mb-10">
        <h1 class="font-bold text-4xl text-blue-500 ms-10 me-10 mb-5">Lost in the Wilderness!</h1>
        <div class="ms-10 me-10 mb-5">
            <p class="overflow-clip lg:mx-80">
                Looks like you've ventured off the map!
                Don't worry; our compass is pointing us in the right direction.
                In the meantime, why not explore other amazing destinations.
            </p>
        </div>
        <button @click="goHome()" type="button" class="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Explore Destinations</button>
    </div>
</template>

<script>
import Navbar from '@/components/NavbarCompThird.vue'
import PageNotFound from '@/components/PageNotFound.vue'
    export default{
        components: { PageNotFound, Navbar },

        methods:{
            goHome(){
                this.$router.push({name: 'Home'})
            }
        },
    }
</script>