<template>
  <router-view />
</template>

<script>
import Parse from 'parse'

Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('A9iU0asj7lBx8THtL3OuHVdKAebIUED9GJKzrpWn', 'AQmS27wK0tZHTPFga30Afa0eMTqnBmzf7zk9yX4F');

export default {
  name: 'App',
  components: {},
  data(){
    return{
      open: false,
    }
  },

  methods: {
    toggle(){
      this.open = !this.open;
    }
  }
}
</script>

<style>
</style>