<template>
    <div class="block p-6 bg-white border border-gray-200 rounded-xl shadow-2xl relative w-auto mx-5 my-2">
    <!-- <div class="bg-opacity-50 bg-blur-md backdrop-filter backdrop-blur-md backdrop-saturate-150 shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 w-auto lg:w-96 border border-lg"> -->
        <div>
            <div class="grid sm:grid-rows-2 grid-rows-1 gap-4">
                <div class="relative">
                    <input type="search" id="from-search" v-model="from_loc" class="block w-full p-4 pl-12 text-sm text-gray-800 border border-gray-200 rounded-md bg-white" placeholder="Where from?" required>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                </div>
                <div class="relative">
                    <input type="search" id="to-search" v-model="to_loc" class="block w-full p-4 pl-12 text-sm text-gray-800 border border-gray-200 rounded-md bg-white" placeholder="Where to?" required>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                    </svg>
                </div>
                <div class="flex items-center">
                    <div class="relative">
                        <VueDatePicker v-model="departure_date" :enable-time-picker="false" placeholder="Departure"/>
                        <!-- <input  datepicker datepicker-autohide type="text" id="from-search" class="block w-full p-4 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50" placeholder="Departure" required> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 16 16">
                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                        </svg> -->
                    </div>
                    <span class="mx-4 text-gray-500">to</span>
                    <div class="relative">
                        <div class="relative">
                            <VueDatePicker v-model="return_date" :enable-time-picker="false" placeholder="Return"/>
                            <!-- <input  datepicker datepicker-autohide type="text" id="from-search" class="block w-full p-4 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50" placeholder="Return" required> -->
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" viewBox="0 0 16 16">
                                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg> -->
                        </div>
                    </div>
                </div>
            </div>
            <button @click="explore_btn" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded-full w-full">
                Explore with us
            </button>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default{
    // props : { from: String, to: String, dDate: String, rDate: String },
    data(){
        return{
            departure_date: null,
            return_date: null,
            from_loc: '',
            to_loc: '',
        };
    },

    // watch: {
    //     from(newVal){
    //         this.from_loc = newVal;
    //         console.log(this.from_loc);
    //     },

    //     to(newVal){
    //         this.to_loc = newVal;
    //         console.log(this.to_loc);
    //     },

    //     dDate(newVal){
    //         this.departure_date = newVal;
    //         console.log(this.departure_date);
    //     },

    //     rDate(newVal){
    //         this.return_date = newVal;
    //         console.log(this.return_date);
    //     },
    // },

    components: {VueDatePicker },
    methods:{
        toggleDropdown() {
            const dropdown = document.getElementById('dropdownAvatarName');
            dropdown.classList.toggle('hidden');
        },

        explore_btn(){
            this.from_loc;
            this.to_loc;
            this.departure_date;
            this.return_date;
            if(this.from_loc == '' && this.to_loc == ''){
                this.PopUpError()
            } else {
                this.$emit('fromLocation', this.from_loc);
                this.$emit('toLocation', this.to_loc);
                this.$emit('departureDate', this.departure_date);
                this.$emit('returnDate', this.return_date);
                this.$router.push({name: 'flightsCatalog', params: {fromLoc: this.from_loc, toLoc: this.to_loc, departureDate: this.departure_date, returnDate: this.return_date} });
                this.PopUp();
                // this.from_loc = '';
                // this.to_loc = '';
                // this.departure_date = '';
                // this.return_date = '';
            }
        },
        
        PopUpError(){
            Swal.fire({
                icon: 'error', title: "Please complete the form", 
                text: "To assist us in finding the most suitable flights for you, kindly provide your details.",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
            });
        },

        PopUp(){
            Swal.fire({
                icon: 'success', title: "Searching ...", 
                text: "Finding the optimal flight for you.",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
            });
        },
    },
};
</script>